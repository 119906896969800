<template>
    <b-modal :ref="modalRef" :title="citation ? 'Édition' : 'Nouvelle citation'"
             hide-footer @hidden="destroyModal" size="lg">
        <div class="text-center">
            <b-form @submit.prevent="submit">
                <b-row>
                    <b-col lg="12">
                        <b-form-group label="Texte">
                            <balloon-editor v-model="form.texte"/>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" offset-lg="3">
                        <b-form-group label="Auteur (si Clujiste)">
                            <utilisateur-multiselect v-model="form.auteur" add-empty-option/>
                        </b-form-group>
                    </b-col>
                    <height-fade-transition>
                        <b-col lg="6" offset-lg="3" v-if="!form.auteur || !form.auteur.id">
                            <b-form-group label="Auteur (si non-Clujiste)">
                                <b-form-input v-model="form.auteurString" type="text"/>
                            </b-form-group>
                        </b-col>
                    </height-fade-transition>
                    <b-col lg="6" offset-lg="3">
                        <b-form-group label="Date">
                            <date-time-picker v-model="form.dateOrigine" :max-datetime="new Date().toISOString()"/>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" offset-lg="3">
                        <b-btn type="submit" variant="success" class="m-3">
                            <font-awesome-icon icon="check"/>
                            Valider
                        </b-btn>
                    </b-col>
                </b-row>
            </b-form>
        </div>
    </b-modal>
</template>

<script>
    import {apiPath}  from '@/util/http';
    import alert      from '@/util/alert';
    import modalMixin from '@/mixin/modalMixin';

    const HeightFadeTransition   = () => import('@/components/HeightFadeTransition');
    const UtilisateurMultiselect = () => import('@/components/UtilisateurMultiselect');
    const BalloonEditor          = () => import('@/components/BalloonEditor');
    const DateTimePicker         = () => import('@/components/DateTimePicker');

    export default {
        name: "EditCitationModal",
        components: {HeightFadeTransition, UtilisateurMultiselect, BalloonEditor, DateTimePicker},
        mixins: [modalMixin],
        props: {
            citation: {
                type: Object,
                default: () => null
            },
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            modalRef: 'editCitationModal',
            uploadPicture: null,
            form: {
                auteurString: null,
                texte: null,
                dateOrigine: null,
                auteur: {
                    id: 0,
                    prenom: ' ',
                    nom: ' ',
                    pseudo: null
                }
            }
        }),
        methods: {
            submit() {
                alert.loading();

                if (!this.form.auteur)
                    this.form.auteur = {id: 0};

                if (this.form.auteur.id) // Make sure we don't have both
                    this.form.auteurString = null;

                this.axios
                    .post(
                        apiPath('edit_citation', this.citation ? {citation: this.citation.id} : {}),
                        {...this.form, auteur: this.form.auteur.id}
                    )
                    .then(() => this.$toaster.success(`Citation ${this.citation ? 'modifiée' : 'créée'} avec succès`))
                    .catch(() => this.$toaster.error("Impossible d'enregistrer la citation"))
                    .finally(() => {
                        this.callback();
                        alert.stopLoading();
                        this.hideModal();
                    });
            }
        },
        mounted() {
            if (this.citation) {
                this.form = {...this.form, ...this.citation};
                if (this.citation.dateOrigine)
                    this.form.dateOrigine = this.citation.dateOrigine.toISOString(true);
            }
        }
    }
</script>
