export default {
    data: () => ({
        modalRef: null
    }),
    methods: {
        destroyModal() {
            this.$store.dispatch('modal/destroy');
        },
        hideModal() {
            this.$refs[this.modalRef].hide();
        }
    },
    mounted() {
        this.$refs[this.modalRef].show();
    }
};